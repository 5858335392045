import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import SocketEvents from 'constants/socket-events.js';
import { SocketState, HelloSettings, DeviceStateNames, UserRoles } from 'constants/enums.js';
import { isAuthenticated, getAccessToken, getUserRole } from 'infrastructure/auth.js';
import { APP_CONFIG, ClientType, AppType } from 'constants/global-variables.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as patientsActionCreators } from 'state/patients/actions.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { SocketFunctionsProvider } from 'infrastructure/socket-client/SocketFunctions.jsx';
import registerE2eeDevice from 'calls/e2ee.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';

const signalingUrl = `${
	process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
		? process.env.REACT_APP_SIGNALING_URL
		: window.__env__.REACT_APP_SIGNALING_URL
}hellohealth`;

const socketIO = io(signalingUrl, {
	secure: true,
	transports: ['websocket'],
	autoConnect: false,
});

/**
 * @typedef {object} props
 * @property {(type: any) => void} props.onConnectionStateChange
 * @property {() => void} props.shouldDisableIncomingCalls
 * @param {import('react').PropsWithChildren<props>} props
 */
const Socket = props => {
	let { current: connectResolveCallback } = useRef(null);
	const connectPromiseRef = useRef(
		new Promise(resolve => {
			connectResolveCallback = resolve;
		})
	);
	let { current: authResolveCallback } = useRef(null);
	const authPromiseRef = useRef(
		new Promise(resolve => {
			authResolveCallback = resolve;
		})
	);
	const clientInfoRef = useRef(null);
	const socketListenersOffRef = useRef([]);

	const healthSystems = useSelector(state => state.healthSystems);
	const dispatch = useDispatch();

	const updateDevicePairedRemote = (deviceId, pairedRemote) => {
		if (pairedRemote) {
			dispatch(devicesActionCreators.setBusyDevice(deviceId));
		} else {
			dispatch(devicesActionCreators.removeBusyDevice(deviceId));
		}
	};

	const updateDeviceCallStatus = (deviceId, activeConferences) => {
		if (activeConferences.length > 0) {
			dispatch(devicesActionCreators.setBusyDevice(deviceId));
		} else {
			dispatch(devicesActionCreators.removeBusyDevice(deviceId));
		}
		dispatch(patientsActionCreators.updateDeviceCallStatus(deviceId, activeConferences));
	};

	const updateDeviceStatus = (deviceId, isOnline) => {
		if (isOnline) {
			dispatch(devicesActionCreators.setOnlineDevice(deviceId));
		} else {
			dispatch(devicesActionCreators.removeOnlineDevice(deviceId));
		}
		dispatch(patientsActionCreators.updateDeviceStatus(deviceId, isOnline));
	};

	const updateDevicePrivacyMode = (deviceId, privacyMode) => {
		// To be merged with DevicePrivacyStatus
		// We dont need two events for the same thing.
		if (privacyMode) {
			dispatch(devicesActionCreators.setPrivacyDevice(deviceId));
		} else {
			dispatch(devicesActionCreators.removePrivacyDevice(deviceId));
		}
		dispatch(patientsActionCreators.updateDeviceAIPrivacyStatus(deviceId, privacyMode));
	};

	const connect = async () => {
		if (!isAuthenticated() || (socket.connected && !clientInfoRef.current)) {
			return;
		}

		if (socket.connected && clientInfoRef.current.token !== getAccessToken()) {
			reAuthorize();
			return;
		}

		initSocketListeners();

		socket.connect();

		await connectPromiseRef.current;
	};

	const disconnect = () => {
		if (socket.connected) {
			socket.disconnect();
			clientInfoRef.current = null;
			socketListenersOffRef.current.forEach(off => off());
			authPromiseRef.current = new Promise(resolve => {
				authResolveCallback = resolve;
			});
			setSocket(prevState => Object.assign(prevState, { authPromise: authPromiseRef.current }));
		}
	};

	const reAuthorize = () => {
		const myClientInfo = {
			token: getAccessToken(),
			clearConferences: false,
			clientType: ClientType,
			appType: AppType,
			versionName: APP_CONFIG.releaseName,
			oldSocketId: socket.id,
			incomingCallsDisabled: props.shouldDisableIncomingCalls(),
		};

		clientInfoRef.current = myClientInfo;
		socket.emit(SocketEvents.Client.AUTHORIZE, myClientInfo, () => {});
	};

	const initSocketListeners = () => {
		let myClientInfo = null;
		let mySocketId = null;

		const bindEventListener = (event, listener) => {
			socket.on(event, listener);
			socketListenersOffRef.current.push(() => socket.off(event, listener));
		};

		const setDeviceCallState = ({ deviceId, activeConferences }) => {
			updateDeviceCallStatus(deviceId, activeConferences);
		};

		const handleDeviceSettingUpdated = ({ deviceId, settingTypeId, settingValue }) => {
			if (settingTypeId === HelloSettings.PRIVACY_MODE) {
				if (settingValue === 'true') {
					dispatch(devicesActionCreators.setPrivacyDevice(deviceId));
				} else {
					dispatch(devicesActionCreators.removePrivacyDevice(deviceId));
				}
				dispatch(patientsActionCreators.updateDeviceAIPrivacyStatus(deviceId, settingValue === 'true'));
			}

			if (settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT && isJSON(settingValue)) {
				if (JSON.parse(settingValue).active) {
					dispatch(devicesActionCreators.setPrivacyDevice(deviceId));
				} else {
					dispatch(devicesActionCreators.removePrivacyDevice(deviceId));
				}
				dispatch(patientsActionCreators.updateDeviceAIPrivacyStatus(deviceId, JSON.parse(settingValue).active));
			}
		};

		bindEventListener(SocketEvents.Client.ON_CONNECT, () => {
			connectResolveCallback();
			// change socket state to SocketState.CONNECTED only if it's from re-connection
			if (mySocketId) {
				props.onConnectionStateChange(SocketState.CONNECTED);
			}
			myClientInfo = {
				token: getAccessToken(),
				clearConferences: false,
				clientType: ClientType,
				appType: AppType,
				versionName: APP_CONFIG.releaseName,
				oldSocketId: mySocketId,
				incomingCallsDisabled: props.shouldDisableIncomingCalls(),
			};
			mySocketId = socket.id;
			clientInfoRef.current = myClientInfo;
			socket.emit(SocketEvents.Client.AUTHORIZE, myClientInfo, () => {});
		});

		bindEventListener(SocketEvents.Client.ON_AUTHENTICATED, () => {
			authResolveCallback();
		});

		bindEventListener(SocketEvents.Client.ON_DISCONNECT, reason => {
			if (reason === 'io server disconnect') {
				// the disconnection was initiated by the server, you need to reconnect manually
				socket.connect();
			}

			if (reason !== 'io client disconnect') {
				// Don't display message if disconnect was from socket client disconnect()
				props.onConnectionStateChange(SocketState.RECONNECTING);
			}
		});

		bindEventListener(SocketEvents.Client.ON_RECONNECT, () => {
			props.onConnectionStateChange(SocketState.CONNECTED);
		});

		bindEventListener(SocketEvents.Client.ON_RECONNECTING, () => {
			props.onConnectionStateChange(SocketState.RECONNECTING);
		});

		bindEventListener(SocketEvents.Client.ON_CONNECT_FAILED, () => {
			props.onConnectionStateChange(SocketState.DISCONNECTED);
		});

		bindEventListener('error', err => {
			/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
			console.error(`Socket.IO error:${err}`);
		});

		bindEventListener(SocketEvents.Client.ON_DEVICE_OFFLINE, data => {
			updateDeviceStatus(data.helloDeviceId, false);
		});

		bindEventListener(SocketEvents.Client.ON_DEVICE_ONLINE, data => {
			updateDeviceStatus(data.helloDeviceId, true);
		});

		bindEventListener(SocketEvents.Client.ON_UPDATE, data => {
			if (!healthSystems.treeData.tree) {
				return;
			}
			updateDeviceStatus(data.helloDeviceId, data.status);
		});

		bindEventListener(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, data => {
			setDeviceCallState(data);
		});

		bindEventListener(SocketEvents.HealthCare.ON_DEVICE_SETTINGS_UPDATED, data => {
			if (data.settingTypeId === HelloSettings.PRIVACY_MODE) {
				updateDevicePrivacyMode(data.deviceId, data.settingValue === 'true');
			} else {
				handleDeviceSettingUpdated(data);
			}
		});

		bindEventListener(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, data => {
			updateDevicePrivacyMode(data.deviceId, data.privacyMode);
		});

		bindEventListener(SocketEvents.HelloDevice.ON_STATE_CHANGED, data => {
			if (data.name === DeviceStateNames.HasPairedRemote) {
				updateDevicePairedRemote(data.deviceId, data.value);
			}
		});

		bindEventListener(SocketEvents.Alerts.NEW_MEASUREMENT_ALERT, data => {
			const isCall = window.location.pathname.startsWith('/call') || window.location.pathname.startsWith('/patient-feed');
			if (getUserRole() === UserRoles.DOCTOR && !isCall && data.measurementAlertType && data.patientFullName) {
				dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(data));
			}
		});
		// registerE2eeDevice(socket);
	};

	const [socket, setSocket] = useState(() =>
		Object.assign(socketIO, {
			doConnect: connect,
			doDisconnect: disconnect,
			authPromise: authPromiseRef.current,
		})
	);

	// Do not move useEffect because react-hooks/exhaustive-deps warning might fire
	useEffect(() => {
		connect();
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SocketContext.Provider value={socket}>
			<SocketFunctionsProvider>{props.children}</SocketFunctionsProvider>
		</SocketContext.Provider>
	);
};

export default Socket;
