import _ from 'lodash';
import {
	AudioRoutingDeviceTypes,
	AudioRoutingOption,
	CompanyRoleObjects,
	UserRoles,
	StatAlarmType,
	TalkToPatient,
	PatientsAiSettings,
} from 'constants/enums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import translate from 'i18n-translations/translate.jsx';

export const NurseMenu = {
	ROUNDING: 27,
	WAITING_ROOM: 24,
	CHAT: 33,
	PATIENTS: 997,
	TEAMS: 34,
	MONITORING: 35,
	RPM: 28,
	WAITING_FOR_RESULTS: 43,
	CHECK_IN: 36,
	VISITORS: 55,
	LOGISTICS: 276,
	AMBIENT_MONITORING: 207,
	CARE_EVENTS: 197,
};

export const VirtualSitterMenu = {
	TEAMS: 34,
	MONITORING: 35,
	AMBIENT_MONITORING: 207,
};

export const PatientMenu = {
	HOME: 23,
	RECENT: 29,
	APPOINTMENTS: 26,
	HEALTH: 30,
	SUMMARY: 31,
	HELLO: 64,
};

export const DoctorMenu = {
	HOME: 23,
	WAITING_ROOM: 24,
	WAITING_ROOM_IN_PERSON: 105,
	PATIENTS: 25,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	RPM: 28,
	LOGISTICS: 276,
	SESSIONS_QUEUE: 186,
};

export const SuperUserMenu = {
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	USER_MANAGEMENT: 18,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
};

export const DigitalClinicianMenu = {
	CHECK_IN: 36,
	PATIENTS: 25,
	RPM: 28,
	VISITS: 44,
	REGISTER_PATIENT: 45,
};

export const SuperAdminMenu = {
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	AUDIT_LOGS: 42,
};

export const AdminMenu = {
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	AUDIT_LOGS: 42,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
};

export const UserSettingTypes = {
	Other: 4, // Company Settings
	Monitoring: 3,
	Navigation: 2,
	GeneralSettings: 1,
	CallsSettings: 8,
	AiSettings: 14,
};

export const MonitoringSettings = {
	RaiseAlert: 57,
	ContactNurses: 58,
	CallPatient: 59,
	WalkieTalkie: 60,
	MicrosoftTeams: 61,
	Call911: 62,
	NightVision: 12,
	EHR: 65,
	Notes: 37,
	CareEventsForNurses: 38,
	CareEventsForSafetyCompanions: 39,
	ForwardToNurses: 40,
	Precautions: 46,
	Privacy: 48,
	Recording: 49,
	DisableVisits: 15,
	DisableAi: 16,
	EWSAIVitalSigns: 109,
	GetOutOfBed: 110,
	Rails: 111,
	PatientWalking: 112,
	FallDetection: 113,
	HandWashing: 114,
	AIPrivacyMode: 115,
	FaceDown: 116,
	PTZCam: 117,
	SmartWatchVitalSigns: 118,
	BiobeatVitalSigns: 119,
	PressureInjury: 120,
	StatAlarm: 144,
	LifeSignals: 145,
	EditRoomNameMonitoring: 147,
	EnableMultipleFeedAudio: 66,
	IVBagFluidLevel: 170,
	PatientCommunication: 202,
	MonitoringHealthData: 216,
	RTLS: 219,
	VerbalRedirection: 220,
	PatientOutOfRoom: 221,
	MixedMonitoringView: 235,
	PatientInfo: 251,
	Whiteboard: 252,
	AiDetectionDrawing: 256,
	SoundOnAiAlert: 257,
};

export const CompanySettings = {
	CLIENT_THEME_COLOR: 1,
	TWO_FACTOR_TYPE: 2,
	LENGTH_UNIT: 4,
	WEIGHT_UNIT: 5,
	TEMPERATURE_UNIT: 6,
	HEIGHT_UNIT: 7,
	BLOOD_GLUCOSE_UNIT: 8,
	DATE_FORMAT: 9,
	TIME_FORMAT: 10,
	LANGUAGE: 11,
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
	E2EE: 106,
	DIAL_OUT: 108,
	MULTIPARTY_CALL: 123,
	HELLO_DEVICE: 121,
	HUDDLE_CAMERA: 122,
	VCP_DISPLAY_NAME: 250,
	BEING_MONITORED_MESSAGE: 261,
	THEME: 3,
	PATIENT_VIRTUAL_ADMISSION: 203,
	PATIENT_MANUAL_ADMISSION: 204,
	PATIENT_EHR_ADMISSION: 205,
	DIGITAL_BACKGROUND: 190,
	BLURRY_BACKGROUND: 191,
	HELLO_DEFAULT_BACKGROUND: 196,
	HELLO_DEFAULT_WALLPAPER: 208,
	SUPPORT_CHAT: 211,
	CAMERA_USE: 218,
};

export const RouteSetting = {
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
};

export const UserSettings = {
	CALL_BACKGROUND: 194,
};

export const CompanyCallSettings = {
	PROVIDER_MIC: 195,
	PATIENT_MIC: 200,
	PROVIDER_CAMERA: 199,
	PATIENT_CAMERA: 201,
};

export const IntegrationTypesSettings = {
	ALEXA: 124,
	TELEHEALTH: 148,
	EVIDEON: 149,
	PCARE: 150,
	GET_WELL: 151,
	SONIFI: 152,
	AZURE_AD: 153,
	PING_FEDERATE: 154,
	OKTA: 254,
	HILL_ROM: 223,
	EPIC: 155,
	CERNER: 156,
	GLOBO: 157,
	AMN: 158,
	HL7_V2: 237,
	MDM: 241,
};

export const HelloCareEngagementSettings = {
	HOME: 172,
	EDUCATION: 173,
	LIVE_TV: 174,
	MOVIES: 175,
	MUSIC: 176,
	MOODS: 177,
	APPS: 178,
	FAMILY_FRIENDS: 179,
	MEALS: 180,
	WHITEBOARD: 181,
	CARE_TEAM: 182,
	HEALTH_DATA: 187,
	PODCASTS: 242,
	GAMES: 243,
	HDMI_IN: 244,
	ROOM_CONTROLS: 245,
	SETTINGS: 260,
	WHITEBOARD_PIP: 273,
};

export const WhiteboardSettings = {
	WHITEBOARD_URL: 226,
	PIN: 246,
};

export const configurableWhiteboardSettings = () => ({
	[WhiteboardSettings.WHITEBOARD_URL]: getConfigurableMenu({
		title: 'whiteboardURL',
		value: 'false',
		isLocked: false,
		isURLField: true,
		valid: true,
		description: 'engagementWhiteboardURLDescription',
		category: 'whiteboardSettings',
	}),
	[WhiteboardSettings.PIN]: getConfigurableMenu({
		title: 'userAutoGeneratedPin',
		value: false,
		isLocked: false,
		description: 'autoGeneratedPinDesc',
		category: 'whiteboardSettings',
	}),
});

export const configurableHelloCareEngagement = () => ({
	[HelloCareEngagementSettings.HOME]: getConfigurableMenu({
		title: 'homeWidget',
		value: true,
		isLocked: false,
		description: 'engagementHomeDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.EDUCATION]: getConfigurableMenu({
		title: 'educationWidget',
		value: true,
		isLocked: false,
		description: 'engagementEducationDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.LIVE_TV]: getConfigurableMenu({
		title: 'liveTvWidget',
		value: true,
		isLocked: false,
		description: 'engagementLiveTVDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MOVIES]: getConfigurableMenu({
		title: 'moviesWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoviesDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MUSIC]: getConfigurableMenu({
		title: 'musicWidget',
		value: true,
		isLocked: false,
		description: 'engagementMusicDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MOODS]: getConfigurableMenu({
		title: 'moodsWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoodsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.APPS]: getConfigurableMenu({
		title: 'appsWidget',
		value: true,
		isLocked: false,
		description: 'engagementAppsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.FAMILY_FRIENDS]: getConfigurableMenu({
		title: 'familyAndFriendsWidget',
		value: true,
		isLocked: false,
		description: 'engagementFamilyFriendsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MEALS]: getConfigurableMenu({
		title: 'orderMealsWidget',
		value: true,
		isLocked: false,
		description: 'engagementOrderMealDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD]: getConfigurableMenu({
		title: 'whiteBoardWidget',
		value: true,
		isLocked: false,
		description: 'engagementWhiteboardDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.CARE_TEAM]: getConfigurableMenu({
		title: 'careTeamWidget',
		value: true,
		isLocked: false,
		description: 'engagementCareTeamDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HEALTH_DATA]: getConfigurableMenu({
		title: 'healthDataWidget',
		value: true,
		isLocked: false,
		description: 'engagementHealthDataDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.PODCASTS]: getConfigurableMenu({
		title: 'podcastsWidget',
		value: true,
		isLocked: false,
		description: 'podcastsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.GAMES]: getConfigurableMenu({
		title: 'gamesWidget',
		value: true,
		isLocked: false,
		description: 'gamesWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HDMI_IN]: getConfigurableMenu({
		title: 'hdmiInWidget',
		value: true,
		isLocked: false,
		description: 'hdmiInWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.ROOM_CONTROLS]: getConfigurableMenu({
		title: 'roomControlsWidget',
		value: true,
		isLocked: false,
		description: 'roomControlsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.SETTINGS]: getConfigurableMenu({
		title: 'settingsWidget',
		value: true,
		isLocked: false,
		description: 'settingsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD_PIP]: getConfigurableMenu({
		title: 'whiteboardPiPWidget',
		value: true,
		isLocked: false,
		description: 'whiteboardPiPWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
});

export const MonitoringFeatureFlagCategories = {
	GENERAL: 'general',
	COMMUNICATE_WITH_PATIENT: 'communicatingWithThePatient',
	COMMUNICATE_WITH_NURSES: 'communicatingWithNurses',
	AUTOMATIC_ALERTS: 'automaticAlerts',
};

export const RoundingFeaturesCategories = {
	GENERAL: 'general',
	LIVE_EXAMINATIONS: 'liveExaminations',
	INVITING_PEOPLE: 'invitingPeople',
	MEDICAL_INFO: 'medicalInfo',
	PATIENT_HISTORY: 'patientHistory',
};
export const AiSettingFeaturesCategory = {
	GENERAL_SETTINGS: 'generalSettings',
	AI_SETTINGS: 'aiSettings',
};

export const PatientTabsFeaturesCategories = {
	PATIENTS: 'patients',
};
export const UserExperienceFeaturesCategories = {
	USER_EXPERIENCE: 'userExperience',
};

export const GeneralAndMenuFeaturesCategories = {
	GENERAL_SETTINGS: 'generalSettings',
	MENU_SETTINGS: 'menuSettings',
};

export const IntegrationFeaturesCategories = {
	EXTERNAL_IDENTITY_PROVIDERS: 'externalIdentityProviders',
	EHR: 'ehr',
	PATIENT_ENGAGEMENT: 'patientEngagement',
	TRANSLATION_SERVICES: 'translationServices',
	ALEXA: 'alexaEnabled',
	NURSE_CALLS_SYSTEMS: 'nurseCallsSystems',
};

export const NoteVariants = {
	SHOW_UNDER_TILE: 'show-under-tile',
	SHOW_ON_EXPAND: 'show-on-expand',
};

export const MixedViewVariants = {
	VIDEO_MONITORING: 'video-monitoring',
	AMBIENT_MONITORING: 'ambient-monitoring',
};

export const CallerNameVariant = {
	FULL_NAME: 'fullname',
	CREDENTIALS: 'credentials',
};

export const SettingsCategory = {
	GENERAL: 1,
	CUSTOMIZATION: 2,
	MONITORING: 3,
	OTHER: 4,
	MOBILE_LIVE_EXAMINATIONS: 5,
	TV_NAVIGATION: 6,
	ROUNDING: 7,
	CALL: 8,
	AUDIO_ROUTING: 9,
	HELLO_CARE_ENGAGEMENT: 11,
	AI_SETTINGS: 14,
	PATIENTS: 15,
	USER_EXPERIENCE: 16,
	DASHBOARD_REPORTS: 17,
};

export const AISettings = {
	DATA_COLLECTION: 217,
};

export const PatientTabs = {
	OVERVIEW: 227,
	FILES: 229,
	HEALTH: 230,
	PHYSICAL_THERAPY: 231,
	VISITS: 232,
	ALERT_HISTORY: 233,
};

export const ConfigSettingType = {
	TOGGLE: 'toggle',
	SELECT: 'select',
	LOCK: 'lock',
};

export const RoundingSettings = {
	ExamKit: 91,
	Wearables: 92,
	MedicalDevices: 93,
	AiMeasurements: 94,
	BiobeatPatch: 125,
	LifeSignalsPatch: 126,
	RoundingVisits: 134,
	RoundingHealthData: 135,
	RoundingAlertHistory: 132,
	PatientDiagnoses: 127,
	PatientProcedures: 128,
	PatientNotes: 129,
	PatientPrescriptions: 130,
	PatientPhysicalTherapy: 131,
	ScreenSharing: 136,
	FitToScreen: 137,
	GridView: 138,
	RoundingNightVision: 139,
	MeetingUrl: 140,
	InviteViaSms: 141,
	TranslationServices: 142,
	InviteViaEmail: 143,
	DialIn: 107,
	DialOut: 108,
	EditRoomNameRounding: 146,
	SessionWrapUp: 185,
	CareEventsForNurses: 188,
	CareEventsForSafetyCompanions: 189,
	DefaultHelloBackground: 196,
	CallerId: 193,
	ClosedCaptions: 198,
	TalkToPatient: 224,
	ViewPatient: 225,
	RoundingCareEvents: 253,
	RateCall: 255,
	Whiteboard: 274,
};

export const GeneralAndMenuSettings = {
	MONITORING_PRECAUTIONS_LEGEND: 14,
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	HOME: 23,
	WAITING_ROOM: 24,
	PATIENTS: 25,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	RPM: 28,
	RECENT: 29,
	HEALTH: 30,
	SUMMARY: 31,
	CHAT: 33,
	TEAMS: 34,
	MONITORING: 35,
	CHECK_IN: 36,
	AUDIT_LOGS: 42,
	WAITING_FOR_RESULTS: 43,
	VISITS: 44,
	REGISTER_PATIENT: 45,
	POOLING_FLOW: 51,
	CONVERSATION_HISTORY: 52,
	NOTIFICATIONS: 53,
	MONITORING_SESSIONS: 54,
	VISITORS: 55,
	HELLO: 64,
	CONVERSATION_FILES: 104,
	CONVERSATION_PHOTOS: 105,
	ALEXA: 124,
	SESSIONS_QUEUE: 186,
	DASHBOARD: 192,
	AMBIENT_MONITORING: 207,
	SUPPORT_CHAT: 211,
	CARE_EVENTS: 197,
	IS_OLD_USER_EXPERIENCE: 234,
	FLEET_MANAGEMENT: 222,
	IS_EXPERIENCE_HS_CHANGEABLE: 236,
	LOGISTICS: 276,
};

export const RoomModesSettings = {
	HELLOCARE: 169,
	AI_WIDGET: 161,
	WIDGET: 162,
	DIGITAL_CLINICIAN: 163,
	TELEMEDICINE: 164,
	HOSPITAL: 165,
	NICU: 159,
	VIRTUAL_EXAMINATION: 166,
	SIMPLIFIED: 167,
	E_VIDEON: 168,
	HELLOCARE_ENGAGEMENT: 160,
};

export const DashboardSettings = {
	OVERVIEW: 238,
	PROVIDER_CENTRIC: 239,
	PATIENT_CENTRIC: 240,
};

const getConfigurableMenu = config => ({ ...config });

export const getConfig = config => {
	if (!config) {
		return { isLocked: false, value: false, description: '' };
	}

	let value;
	try {
		value = JSON.parse(config.value);
	} catch (_error) {
		value = config.value;
	}

	return {
		isLocked: config.isLocked,
		value,
		description: config.description,
	};
};

export const CustomRoomModesSettings = {
	INTAKE_FORM: 183,
	DOCTOR_POOLING: 184,
	RAPID_CALL: 247,
	REGULAR_CALL: 248,
	ADMISSION_CALL: 249,
	SUBMIT_NEW_CASE: 259,
};

export const IntakeFormVariants = {
	SCP_HEALTH_FORM: 'scp-health-form',
	BAYCARE_FORM: 'baycare-form',
};

export const configurableDashboardSettings = () => ({
	[DashboardSettings.OVERVIEW]: getConfigurableMenu({
		title: 'overview',
		value: true,
		description: 'overviewDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PROVIDER_CENTRIC]: getConfigurableMenu({
		title: 'careTeamReport',
		value: true,
		description: 'providerCentricReportDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PATIENT_CENTRIC]: getConfigurableMenu({
		title: 'patientReport',
		value: true,
		description: 'patientCentricReportDescription',
		category: 'dashboardSettings',
	}),
});

export const configurableCustomRoomModesSettings = () => ({
	[CustomRoomModesSettings.INTAKE_FORM]: getConfigurableMenu({
		title: 'intakeForm',
		value: false,
		description: 'intakeFormDescription',
		variant: {
			value: IntakeFormVariants.SCP_HEALTH_FORM,
			label: 'scpHealthForm',
		},
		options: [
			{ value: IntakeFormVariants.SCP_HEALTH_FORM, label: 'scpHealthForm' },
			{ value: IntakeFormVariants.BAYCARE_FORM, label: 'baycareForm' },
		],
		category: 'customRoomModesSettings',
	}),
	[CustomRoomModesSettings.DOCTOR_POOLING]: getConfigurableMenu({
		title: 'doctorPooling',
		value: false,
		description: 'doctorPoolingDescription',
		category: 'customRoomModesSettings',
	}),
	[CustomRoomModesSettings.RAPID_CALL]: getConfigurableMenu({
		title: 'rapidCall',
		value: false,
		description: 'rapidCallDescription',
		category: 'customRoomModesSettings',
	}),
	[CustomRoomModesSettings.REGULAR_CALL]: getConfigurableMenu({
		title: 'regularCall',
		value: false,
		description: 'regularCallDescription',
		category: 'customRoomModesSettings',
	}),
	[CustomRoomModesSettings.ADMISSION_CALL]: getConfigurableMenu({
		title: 'admissionCall',
		value: false,
		description: 'admissionCallDescription',
		category: 'customRoomModesSettings',
	}),
	[CustomRoomModesSettings.SUBMIT_NEW_CASE]: getConfigurableMenu({
		title: 'submitNewCase',
		value: false,
		description: 'submitNewCaseDescription',
		category: 'customRoomModesSettings',
	}),
});

export const configurableRoomType = () => [
	{
		value: RoomTypes.DEFAULT.type,
		label: 'room',
	},
	{
		value: RoomTypes.BABY_ROOM.type,
		label: 'babyRoom',
	},
];

export const configurableRoomModesSettings = () => ({
	[`${RoomModesSettings.NICU}${RoomTypes.BABY_ROOM.type}`]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.BABY_ROOM.type,
		settingTypeId: RoomModesSettings.NICU,
		title: 'nicuMode',
		description: 'nicuDescription',
		category: 'roomModes',
	}),
	[`${RoomModesSettings.NICU}${RoomTypes.DEFAULT.type}`]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		settingTypeId: RoomModesSettings.NICU,
		title: 'nicuMode',
		description: 'nicuDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.AI_WIDGET]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'aiWidgetMode',
		description: 'aiWidgetDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.WIDGET]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'widgetMode',
		description: 'widgetDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.DIGITAL_CLINICIAN]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'digitalClinicMode',
		description: 'digitalClinicDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.TELEMEDICINE]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'telemedicineMode',
		description: 'telemedicineDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.HOSPITAL]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'hospitalMode',
		description: 'hospitalDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.VIRTUAL_EXAMINATION]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'virtualExaminationMode',
		description: 'virtualExaminationDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.SIMPLIFIED]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'simplifiedMode',
		description: 'simplifiedDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.E_VIDEON]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'eVideonMode',
		description: 'eVideonDescription',
		category: 'roomModes',
	}),
	[RoomModesSettings.HELLOCARE_ENGAGEMENT]: getConfigurableMenu({
		value: true,
		roomTypeId: RoomTypes.DEFAULT.type,
		title: 'hellocareEngagement',
		description: 'patientExperienceDescription',
		category: 'roomModes',
	}),
});

export const SelectTypes = {
	TEAMS_SETTINGS: 1,
	ROLE_SETTINGS: 2,
};

export const configurableRoundingMenu = (role = UserRoles.ADMIN) => ({
	[RoundingSettings.EditRoomNameRounding]: getConfigurableMenu({
		title: 'editRoomNameRounding',
		value: false,
		description: 'editRoomNameRoundingDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.ClosedCaptions]: getConfigurableMenu({
		title: 'closedCaptions',
		value: false,
		description: 'closedCaptionsDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.TalkToPatient]: getConfigurableMenu({
		title: 'talkToPatient',
		value: true,
		description: 'talkToPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.RateCall]: getConfigurableMenu({
		title: 'rateCallQuality',
		value: true,
		description: 'rateCallQualityDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.ViewPatient]: getConfigurableMenu({
		title: 'viewPatient',
		value: true,
		description: 'viewPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.Whiteboard]: getConfigurableMenu({
		title: 'whiteboard',
		value: true,
		description: 'roundingWhiteboardDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.DialIn]: getConfigurableMenu({
		title: 'dialIn',
		value: false,
		description: 'dialInDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.DialOut]: getConfigurableMenu({
		title: 'dialOut',
		value: false,
		description: 'dialOutDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.InviteViaEmail]: getConfigurableMenu({
		title: 'inviteViaEmail',
		value: true,
		description: 'inviteViaEmailDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.TranslationServices]: getConfigurableMenu({
		title: 'translationServices',
		value: false,
		description: 'translationServiceDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.InviteViaSms]: getConfigurableMenu({
		title: 'inviteSms',
		value: true,
		description: 'inviteViaSmsDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.MeetingUrl]: getConfigurableMenu({
		title: 'meetingUrl',
		value: true,
		description: 'meetingUrlDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.ExamKit]: getConfigurableMenu({
		title: 'examKit',
		value: false,
		description: 'examKitDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.Wearables]: getConfigurableMenu({
		title: 'wearables',
		value: false,
		description: 'wearablesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.MedicalDevices]: getConfigurableMenu({
		title: 'medicalDevices',
		value: false,
		description: 'medicalDevicesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.AiMeasurements]: getConfigurableMenu({
		title: 'aiMeasurements',
		value: false,
		description: 'AIMeasurementsDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.BiobeatPatch]: getConfigurableMenu({
		title: 'biobeatPatch',
		value: false,
		description: 'biobeatPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.LifeSignalsPatch]: getConfigurableMenu({
		title: 'lifesignalsPatch',
		value: false,
		description: 'lifeSignalsPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.RoundingHealthData]: getConfigurableMenu({
		title: 'roundingHealthData',
		value: false,
		description: 'healthDataDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingVisits]: getConfigurableMenu({
		title: 'roundingVisits',
		value: false,
		description: 'visitsDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingAlertHistory]: getConfigurableMenu({
		title: 'alertHistory',
		value: false,
		description: 'alertHistoryDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(role) && {
		[RoundingSettings.CareEventsForNurses]: getConfigurableMenu({
			title: 'roundingCareEventsForNurses',
			value: false,
			description: 'careEventsForNursesDescription',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
		}),
		[RoundingSettings.CareEventsForSafetyCompanions]: getConfigurableMenu({
			title: 'careEventsForSafetyCompanions',
			value: false,
			description: 'careEventsForNursesDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
		}),
		[RoundingSettings.RoundingCareEvents]: getConfigurableMenu({
			title: 'roundingCareEvents',
			value: false,
			description: 'roundingCareEventsDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
		}),
	}),
	...([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(role) && {
		[RoundingSettings.CallerId]: getConfigurableMenu({
			title: 'callerId',
			value: false,
			isLocked: false,
			description: 'callerIdDescription',
			category: RoundingFeaturesCategories.GENERAL,
			variant: { value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
		}),
	}),
	...([UserRoles.NURSE, UserRoles.DOCTOR].includes(role) && {
		[RoundingSettings.CallerId]: getConfigurableMenu({
			title: 'callerId',
			type: SelectTypes.ROLE_SETTINGS,
			value: false,
			isLocked: false,
			description: 'callerIdDescription',
			category: RoundingFeaturesCategories.GENERAL,
			variant: { value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
			options: [
				{ value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
				{
					value: CallerNameVariant.CREDENTIALS,
					label: 'displayCredentialsName',
				},
			],
		}),
	}),
	...(role !== UserRoles.NURSE && {
		[RoundingSettings.PatientDiagnoses]: getConfigurableMenu({
			title: 'medicalInfoPatientDiagnosis',
			value: false,
			description: 'patientDiagnosesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientProcedures]: getConfigurableMenu({
			title: 'medicalInfoProcedures',
			value: false,
			description: 'proceduresDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientNotes]: getConfigurableMenu({
			title: 'medicalInfoNotes',
			value: false,
			description: 'roundingNotesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPrescriptions]: getConfigurableMenu({
			title: 'medicalInfoPrescriptions',
			value: false,
			description: 'prescriptionsDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPhysicalTherapy]: getConfigurableMenu({
			title: 'medicalInfoPhysicalTherapy',
			value: false,
			description: 'physicalTherapyDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.SessionWrapUp]: getConfigurableMenu({
			title: 'wrapUpPage',
			value: false,
			description: 'sessionWrapUpDescription',
			category: RoundingFeaturesCategories.GENERAL,
		}),
	}),
});

export const configurableAISettings = () => ({
	[AISettings.DATA_COLLECTION]: getConfigurableMenu({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
	}),
	[MonitoringSettings.AiDetectionDrawing]: getConfigurableMenu({
		title: 'aIDetectionDrawings',
		value: false,
		isLocked: false,
		description: 'aiDetectionDrawingsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
	}),
	[MonitoringSettings.SoundOnAiAlert]: getConfigurableMenu({
		title: 'soundOnAiAlert',
		value: false,
		isLocked: false,
		description: 'soundOnAiAlertDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
	}),
	[MonitoringSettings.EWSAIVitalSigns]: getConfigurableMenu({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
	[MonitoringSettings.GetOutOfBed]: getConfigurableMenu({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
	}),
	[MonitoringSettings.Rails]: getConfigurableMenu({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientsAiSettings.RAILS,
	}),
	[MonitoringSettings.IVBagFluidLevel]: getConfigurableMenu({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
	[MonitoringSettings.FallDetection]: getConfigurableMenu({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientsAiSettings.FALL_DETECTED,
	}),
	[MonitoringSettings.HandWashing]: getConfigurableMenu({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
	[MonitoringSettings.AIPrivacyMode]: getConfigurableMenu({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
	[MonitoringSettings.FaceDown]: getConfigurableMenu({
		title: 'faceDown',
		value: false,
		isLocked: false,
		description: 'faceDownDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
	[MonitoringSettings.DisableAi]: getConfigurableMenu({
		title: 'disableAiTemporarily',
		value: false,
		isLocked: false,
		description: 'disableAiDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
	}),
	[MonitoringSettings.PressureInjury]: getConfigurableMenu({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientsAiSettings.PRESSURE_INJURY,
	}),
	[MonitoringSettings.PatientWalking]: getConfigurableMenu({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
	}),
});

export const configurablePatientTabs = () => ({
	[PatientTabs.OVERVIEW]: getConfigurableMenu({
		title: 'overview',
		value: true,
		isLocked: false,
		description: 'overviewTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.FILES]: getConfigurableMenu({
		title: 'files',
		value: true,
		isLocked: false,
		description: 'filesTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.HEALTH]: getConfigurableMenu({
		title: 'health',
		value: true,
		isLocked: false,
		description: 'healthTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.PHYSICAL_THERAPY]: getConfigurableMenu({
		title: 'physicalTherapy',
		value: true,
		isLocked: false,
		description: 'physicalTherapyTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.VISITS]: getConfigurableMenu({
		title: 'visits',
		value: true,
		isLocked: false,
		description: 'visitTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.ALERT_HISTORY]: getConfigurableMenu({
		title: 'alertHistory',
		value: true,
		isLocked: false,
		description: 'alertHistoryTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
});

export const configurableUserExperienceSettings = role => ({
	[GeneralAndMenuSettings.IS_OLD_USER_EXPERIENCE]: getConfigurableMenu({
		title: 'isOldUserExperience',
		value: false,
		isLocked: false,
		description: 'isOldUserExperienceDescription',
		category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
	}),
	...([UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.IS_EXPERIENCE_HS_CHANGEABLE]: getConfigurableMenu({
			title: 'isEnabledForHs',
			value: false,
			isLocked: false,
			description: 'isEnabledForHsDescription',
			category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
		}),
	}),
});

export const configurableGeneralAndMenuSettings = (role = UserRoles.ADMIN) => ({
	...([UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.HOME]: getConfigurableMenu({
			title: 'homePage',
			value: true,
			description: 'homePageDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.WAITING_ROOM]: getConfigurableMenu({
			title: 'waitingRoom',
			value: true,
			description: 'waitingRoomDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.SESSIONS_QUEUE]: getConfigurableMenu({
			title: 'sessionsQueue',
			value: true,
			description: 'sessionsQueueDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.PATIENTS]: getConfigurableMenu({
			title: 'patients',
			value: true,
			description: 'patientsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.APPOINTMENTS]: getConfigurableMenu({
			title: 'appointments',
			value: true,
			description: 'apppointmentsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ROUNDING]: getConfigurableMenu({
			title: 'rounding',
			value: true,
			description: 'roundingDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.RPM]: getConfigurableMenu({
			title: 'remotePatientMonitoring',
			value: true,
			description: 'rpmDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.LOGISTICS]: getConfigurableMenu({
			title: 'logistics',
			value: true,
			description: 'logisticsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: getConfigurableMenu({
			title: 'conversationWithPatient',
			value: true,
			description: 'conversationHistoryDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.NOTIFICATIONS]: getConfigurableMenu({
			title: 'notifications',
			value: true,
			description: 'notificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_FILES]: getConfigurableMenu({
			title: 'conversationFiles',
			value: true,
			description: 'conversationFilesDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: getConfigurableMenu({
			title: 'conversationPhotos',
			value: true,
			description: 'conversationPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.WAITING_ROOM]: getConfigurableMenu({
			title: 'waitingRoom',
			value: true,
			description: 'waitingRoomDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CARE_EVENTS]: getConfigurableMenu({
			title: 'careEventsDashboard',
			value: true,
			description: 'careEventsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ROUNDING]: getConfigurableMenu({
			title: 'rounding',
			value: true,
			description: 'roundingDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.RPM]: getConfigurableMenu({
			title: 'remotePatientMonitoring',
			value: true,
			description: 'rpmDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.LOGISTICS]: getConfigurableMenu({
			title: 'logistics',
			value: true,
			description: 'logisticsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CHAT]: getConfigurableMenu({
			title: 'chat',
			value: true,
			description: 'chatDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.TEAMS]: getConfigurableMenu({
			title: 'teams',
			value: true,
			description: 'teamsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING]: getConfigurableMenu({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.VISITORS]: getConfigurableMenu({
			title: 'visitors',
			value: true,
			description: 'visitorsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: getConfigurableMenu({
			title: 'monitoringPrecautionsLegend',
			value: true,
			description: 'monitoringPrecautionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.POOLING_FLOW]: getConfigurableMenu({
			title: 'poolingFlow',
			value: true,
			description: 'poolingFlowDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: getConfigurableMenu({
			title: 'conversationWithPatient',
			value: true,
			description: 'conversationHistoryDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.NOTIFICATIONS]: getConfigurableMenu({
			title: 'notifications',
			value: true,
			description: 'notificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurableMenu({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_FILES]: getConfigurableMenu({
			title: 'conversationFiles',
			value: true,
			description: 'conversationFilesDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: getConfigurableMenu({
			title: 'conversationPhotos',
			value: true,
			description: 'conversationPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurableMenu({
			title: 'ambientMonitoring',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.VIRTUAL_SITTER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.MONITORING]: getConfigurableMenu({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: getConfigurableMenu({
			title: 'monitoringPrecautionsLegend',
			value: true,
			description: 'monitoringPrecautionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurableMenu({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurableMenu({
			title: 'ambientMonitoring',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.TEAMS]: getConfigurableMenu({
			title: 'teams',
			value: true,
			description: 'teamsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.DIGITAL_CLINICIAN, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.PATIENTS]: getConfigurableMenu({
			title: 'patients',
			value: true,
			description: 'patientsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.RPM]: getConfigurableMenu({
			title: 'remotePatientMonitoring',
			value: true,
			description: 'rpmDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.DASHBOARD]: getConfigurableMenu({
			title: 'dashboard',
			value: true,
			description: 'dashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.FLEET_MANAGEMENT]: getConfigurableMenu({
			title: 'fleetDashboard',
			value: true,
			description: 'fleetDashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.QUEUE_MANAGEMENT]: getConfigurableMenu({
			title: 'queueManagement',
			value: true,
			description: 'queueManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ORGANIZATION]: getConfigurableMenu({
			title: 'organization',
			value: true,
			description: 'organizationDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CALL_LOGS]: getConfigurableMenu({
			title: 'callLogs',
			value: true,
			description: 'callLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.USER_MANAGEMENT]: getConfigurableMenu({
			title: 'userManagement',
			value: true,
			description: 'userManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONFIGURATION]: getConfigurableMenu({
			title: 'configurations',
			value: true,
			description: 'configurationsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.SUPPORT_CHAT]: getConfigurableMenu({
			title: 'supportChat',
			value: true,
			description: 'supportChatDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AUDIT_LOGS]: getConfigurableMenu({
			title: 'auditLogs',
			value: true,
			description: 'auditLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
});

export const configurableMonitoringMenu = (role, isCompanyOrHSLevel = false, isNewExperience = true) => ({
	[MonitoringSettings.EditRoomNameMonitoring]: getConfigurableMenu({
		title: 'editRoomNameMonitoring',
		value: false,
		description: 'editRoomNameMonitoringDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.LifeSignals]: getConfigurableMenu({
		title: 'lifeSignals',
		value: false,
		isLocked: false,
		description: 'lifeSignalDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.StatAlarm]: getConfigurableMenu({
		title: 'statAlarm',
		value: false,
		isLocked: false,
		description: 'statAlarmDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		hasButton: true,
		variant: role === UserRoles.SUPER_ADMIN ? { value: StatAlarmType.ALARM_1 } : {},
		options: [{ value: StatAlarmType.ALARM_1 }, { value: StatAlarmType.ALARM_2 }],
	}),
	[MonitoringSettings.SmartWatchVitalSigns]: getConfigurableMenu({
		title: 'smartWatchVitalSigns',
		value: false,
		isLocked: false,
		description: 'smartWatchVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.BiobeatVitalSigns]: getConfigurableMenu({
		title: 'biobeatVitalSigns',
		value: false,
		isLocked: false,
		description: 'biobeatVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.PTZCam]: getConfigurableMenu({
		title: 'ptzCam',
		value: true,
		isLocked: false,
		description: 'pTZControlsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.ContactNurses]: getConfigurableMenu({
		title: 'contactNurse',
		value: false,
		isLocked: true,
		description: 'contactNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CallPatient]: getConfigurableMenu({
		title: 'callPatient',
		value: false,
		isLocked: false,
		description: 'callPatientDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.PatientCommunication]: getConfigurableMenu({
		title: 'patientCommunication',
		value: false,
		isLocked: false,
		description: 'patientCommunicationDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.WalkieTalkie]: getConfigurableMenu({
		title: 'walkieTalkie',
		value: false,
		isLocked: true,
		description: 'walkieTalkieDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.RTLS]: getConfigurableMenu({
		title: 'rtls',
		value: false,
		isLocked: false,
		description: 'rtlsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MicrosoftTeams]: getConfigurableMenu({
		title: 'microsoftTeams',
		value: false,
		isLocked: true,
		description: 'microsoftTeamsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Call911]: getConfigurableMenu({
		title: 'call911',
		value: false,
		isLocked: true,
		description: 'call911Description',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.NightVision]: getConfigurableMenu({
		title: 'nightVision',
		value: true,
		isLocked: false,
		description: 'nightVisionDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.EHR]: getConfigurableMenu({
		title: 'ehr',
		value: false,
		isLocked: true,
		description: 'EHRDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.Whiteboard]: getConfigurableMenu({
		title: 'whiteboard',
		value: false,
		isLocked: true,
		description: 'monitoringWhiteboardDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.CareEventsForNurses]: getConfigurableMenu({
		title: 'careEventsForNurses',
		value: false,
		isLocked: true,
		description: 'careEventsForNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CareEventsForSafetyCompanions]: getConfigurableMenu({
		title: 'careEventsForSafetyCompanions',
		value: false,
		isLocked: true,
		description: 'careEventsForNursesDesc',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Notes]: getConfigurableMenu({
		title: 'notes',
		value: false,
		isLocked: false,
		description: 'notesDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		variant: { value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
		options: [
			{ value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
			{ value: NoteVariants.SHOW_UNDER_TILE, label: 'showNotesUnderTile' },
		],
	}),
	[MonitoringSettings.Precautions]: getConfigurableMenu({
		title: 'precautions',
		value: false,
		isLocked: false,
		description: 'precautionsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.Recording]: getConfigurableMenu({
		title: 'recordTheSession',
		value: false,
		isLocked: true,
		description: 'recordingDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.Privacy]: getConfigurableMenu({
		title: 'enablePrivacyMode',
		value: false,
		isLocked: false,
		description: 'privacyDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MonitoringHealthData]: getConfigurableMenu({
		title: 'healthData',
		value: false,
		isLocked: false,
		description: 'monitoringHealthDataDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.PatientInfo]: getConfigurableMenu({
		title: 'patientInfo',
		value: false,
		isLocked: false,
		description: 'patientInfoDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.ForwardToNurses]: getConfigurableMenu({
		title: 'forwardToNurses',
		value: false,
		isLocked: true,
		description: 'forwardToNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.VerbalRedirection]: getConfigurableMenu({
		title: 'voiceAnnouncements',
		value: false,
		isLocked: true,
		description: 'voiceAnnouncementsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
	}),
	[MonitoringSettings.PatientOutOfRoom]: getConfigurableMenu({
		title: 'patientOutOfRoom',
		value: false,
		isLocked: true,
		description: 'patientOutOfRoomDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
	}),

	...(role !== UserRoles.VIRTUAL_SITTER && {
		[MonitoringSettings.RaiseAlert]: getConfigurableMenu({
			title: 'raiseAnAlertManually',
			value: false,
			isLocked: true,
			description: 'raiseAlertDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		}),
		[MonitoringSettings.DisableVisits]: getConfigurableMenu({
			title: 'disableVisits',
			value: false,
			isLocked: true,
			description: 'disableVisitsDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
	}),
	...(isCompanyOrHSLevel && {
		[MonitoringSettings.EnableMultipleFeedAudio]: getConfigurableMenu({
			title: 'enableMultipleFeedAudio',
			value: false,
			isLocked: false,
			description: 'enableMultipleFeedAudioDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
		...(isNewExperience && {
			[MonitoringSettings.MixedMonitoringView]: getConfigurableMenu({
				title: 'mixedMonitoringView',
				value: false,
				isLocked: true,
				description: 'mixedMonitoringViewDescription',
				category: MonitoringFeatureFlagCategories.GENERAL,
				variant: {
					value: MixedViewVariants.VIDEO_MONITORING,
					label: 'defaultVideoMonitoring',
				},
				options: [
					{
						value: MixedViewVariants.VIDEO_MONITORING,
						label: 'defaultVideoMonitoring',
					},
					{
						value: MixedViewVariants.AMBIENT_MONITORING,
						label: 'defaultAmbientMonitoring',
					},
				],
				isNotUserPreference: true,
			}),
		}),
	}),
});

export const configurableIntegrationMenu = () => ({
	[IntegrationTypesSettings.AZURE_AD]: getConfigurableMenu({
		title: 'azureAd',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.PING_FEDERATE]: getConfigurableMenu({
		title: 'pingFederate',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.OKTA]: getConfigurableMenu({
		title: 'okta',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.HILL_ROM]: getConfigurableMenu({
		title: 'hillRom',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.NURSE_CALLS_SYSTEMS,
	}),
	[IntegrationTypesSettings.EPIC]: getConfigurableMenu({
		title: 'epic',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.CERNER]: getConfigurableMenu({
		title: 'cerner',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.HL7_V2]: getConfigurableMenu({
		title: 'hl7v2',
		value: false,
		description: 'hl7v2Description',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.TELEHEALTH]: getConfigurableMenu({
		title: 'telehealth',
		value: false,
		isLocked: false,
		description: 'telehealthDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.EVIDEON]: getConfigurableMenu({
		title: 'evideon',
		value: false,
		isLocked: false,
		description: 'evideoDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.PCARE]: getConfigurableMenu({
		title: 'pcare',
		value: false,
		isLocked: false,
		description: 'pcareDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GET_WELL]: getConfigurableMenu({
		title: 'getwell',
		value: false,
		isLocked: false,
		description: 'getwellDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.SONIFI]: getConfigurableMenu({
		title: 'sonifi',
		value: false,
		isLocked: false,
		description: 'sonifiDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.MDM]: getConfigurableMenu({
		title: 'mdm',
		value: false,
		isLocked: false,
		description: 'mdmDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GLOBO]: getConfigurableMenu({
		title: 'globo',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.AMN]: getConfigurableMenu({
		title: 'amn',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.ALEXA]: getConfigurableMenu({
		title: 'alexaEnabled',
		value: false,
		description: 'enableAlexaConfiguration',
		category: IntegrationFeaturesCategories.ALEXA,
	}),
});

export const ThemeTypes = {
	LIGHT: 'light',
	DARK: 'dark',
};

export const themeOptions = [
	{
		id: 0,
		value: ThemeTypes.LIGHT,
		icon: 'light_mode',
	},
	{
		id: 1,
		value: ThemeTypes.DARK,
		icon: 'dark_mode',
	},
];

export const talkToPatientTypes = intl => [
	{
		id: 0,
		content: intl.formatMessage({ id: 'audioOnly' }),
		value: TalkToPatient.AUDIO_ONLY,
	},
	{
		id: 1,
		content: intl.formatMessage({ id: 'audioVideo' }),
		value: TalkToPatient.AUDIO_VIDEO,
	},
];

const getConfigurableCallMenu = (description, value) => ({
	value,
	description,
});

export const ConfigurableDigitalBackgroundSettings = {
	[CompanySettings.DIGITAL_BACKGROUND]: getConfigurableCallMenu('digitalBackground', 'false'),
	[CompanySettings.BLURRY_BACKGROUND]: getConfigurableCallMenu('blurryBackground', 'false'),
	[CompanySettings.HELLO_DEFAULT_BACKGROUND]: getConfigurableCallMenu('helloDefaultBackground', 'false'),
	[CompanySettings.HELLO_DEFAULT_WALLPAPER]: getConfigurableCallMenu('helloDefaultWallpaper', ''),
};

export const PTZCameraSettings = {
	REVERSE_CAMERA: 19,
};

export const GeneralSettings = {
	PoolingFlow: 51,
	ConversationHistory: 52,
	Notifications: 53,
	MonitoringSessions: 54,
	MonitoringPrecautionsLegend: 14,
	NICU_CHAT: 50, // TEMPORARY
	CONVERSATION_FILES: 104,
	CONVERSATION_PHOTOS: 105,
	Alexa: 124,
	CallerId: 193,
};

// Configurations View enums
export const ConfigHistoryTypes = {
	UserIdle: '1',
	Devices: '2',
	ADs: '3',
	TeamConfigurationProfiles: '4',
	NetworkBandwidth: '5',
	NetworkWiFiBand: '6',
	NetworkAccess: '7',
	NtpConfigurations: '8',
};

export const TeamTypeLabels = {
	4: 'Health System',
	5: 'Region',
	6: 'Hospital',
	7: 'Department',
	8: 'Floor',
};

export const TVs = {
	LG: { id: 1, value: 'LG (MPI)' },
	SAMSUNG: { id: 2, value: 'Samsung (Exlink)' },
	TELEHEALTH_CONTROLLED: { id: 3, value: 'Telehealth' },
	EVIDEON: { id: 4, value: 'eVideon' },
	PCARE: { id: 5, value: 'pCare' },
	GET_WELL: { id: 6, value: 'GetWell' },
	SONIFY: { id: 7, value: 'Sonifi' },
	MDM: { id: 8, value: 'MDM' },
};

export const EndCallSource = intl => ({
	TV_CHANNELS: { id: 0, value: intl.formatMessage({ id: 'tvChannels' }) },
	HDMI_1: { id: 1, value: 'HDMI 1' },
	HDMI_2: { id: 2, value: 'HDMI 2' },
	HDMI_3: { id: 3, value: 'HDMI 3' },
	PREVIOUS_STATE: { id: 4, value: intl.formatMessage({ id: 'previousState' }) },
});

export const TeamConfigurationIntegrationTypes = {
	TELEHEALTH: 1,
	PCARE: 2,
	EVIDEON: 3,
	GET_WELL: 4,
	SONIFI: 5,
	MDM: 6,
};

export const VisitorMenu = {
	DEVICES: 67,
	CHAT: 50,
};

export const getAdminConfigurableMenu = showItem => ({
	[UserRoles.SUPER_ADMIN]: {
		[SuperAdminMenu.COMPANIES]: {
			isGeneralSetting: false,
			reDirectUrl: '/companies',
			value: showItem,
			orderNo: 1,
		},
		[SuperAdminMenu.USER_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/users',
			value: showItem,
			orderNo: 2,
		},
		[SuperAdminMenu.AUDIT_LOGS]: {
			isGeneralSetting: false,
			reDirectUrl: '/audit-logs',
			value: showItem,
			orderNo: 3,
		},
	},
	[UserRoles.ADMIN]: {
		[AdminMenu.ORGANIZATION]: {
			isGeneralSetting: false,
			reDirectUrl: '/health-system',
			value: true,
			orderNo: 1,
		},
		[AdminMenu.CALL_LOGS]: {
			isGeneralSetting: false,
			reDirectUrl: '/call-logs',
			value: true,
			orderNo: 2,
		},
		[AdminMenu.USER_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/users',
			value: true,
			orderNo: 3,
		},
		[AdminMenu.CONFIGURATION]: {
			isGeneralSetting: false,
			reDirectUrl: '/configurations',
			value: true,
			orderNo: 4,
		},
		[AdminMenu.AUDIT_LOGS]: {
			isGeneralSetting: false,
			reDirectUrl: '/audit-logs',
			value: showItem,
			orderNo: 5,
		},
		[AdminMenu.DASHBOARD]: {
			isGeneralSetting: false,
			reDirectUrl: '/dashboard',
			value: showItem,
			orderNo: 6,
		},
		[AdminMenu.FLEET_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/fleet-management',
			value: showItem,
			orderNo: 7,
		},
	},
	[UserRoles.SUPER_USER]: {
		[SuperUserMenu.ORGANIZATION]: {
			isGeneralSetting: false,
			reDirectUrl: '/health-system',
			value: showItem,
			orderNo: 1,
		},
		[SuperUserMenu.CALL_LOGS]: {
			isGeneralSetting: false,
			reDirectUrl: '/call-logs',
			value: showItem,
			orderNo: 2,
		},
		[SuperUserMenu.USER_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/users',
			value: showItem,
			orderNo: 3,
		},
		[SuperUserMenu.CONFIGURATION]: {
			isGeneralSetting: false,
			reDirectUrl: '/configurations',
			value: showItem,
			orderNo: 4,
		},
		[SuperUserMenu.QUEUE_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/queue-management',
			value: showItem,
			orderNo: 5,
		},
		[SuperUserMenu.FLEET_MANAGEMENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/fleet-management',
			value: showItem,
			orderNo: 6,
		},
		[GeneralSettings.CONVERSATION_FILES]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 15,
		},
		[GeneralSettings.CONVERSATION_PHOTOS]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 16,
		},
		[SuperUserMenu.DASHBOARD]: {
			isGeneralSetting: false,
			reDirectUrl: '/dashboard',
			value: showItem,
			orderNo: 17,
		},
	},
	[UserRoles.DOCTOR]: {
		[DoctorMenu.HOME]: {
			isGeneralSetting: false,
			reDirectUrl: '/doctor-home',
			component: null,
			value: showItem,
			orderNo: 1,
		},
		[DoctorMenu.SESSIONS_QUEUE]: {
			isGeneralSetting: false,
			reDirectUrl: '/sessions-queue',
			value: showItem,
			orderNo: 2,
		},
		[DoctorMenu.WAITING_ROOM]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room',
			value: showItem,
			orderNo: 3,
		},
		[DoctorMenu.WAITING_ROOM_IN_PERSON]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room-inperson',
			value: showItem,
			orderNo: 11,
		},
		[DoctorMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/patients',
			value: showItem,
			orderNo: 4,
		},
		[DoctorMenu.APPOINTMENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/appointments',
			value: showItem,
			orderNo: 5,
		},
		[DoctorMenu.ROUNDING]: {
			isGeneralSetting: false,
			reDirectUrl: '/health-system',
			value: showItem,
			orderNo: 6,
		},
		[DoctorMenu.RPM]: {
			isGeneralSetting: false,
			reDirectUrl: '/patient-monitoring',
			value: showItem,
			orderNo: 7,
		},
		[DoctorMenu.LOGISTICS]: {
			isGeneralSetting: false,
			reDirectUrl: '/logistics',
			value: showItem,
			orderNo: 11,
		},
		[GeneralSettings.ConversationHistory]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 8,
		},
		[GeneralSettings.Notifications]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 9,
		},
		[GeneralSettings.CONVERSATION_FILES]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 15,
		},
		[GeneralSettings.CONVERSATION_PHOTOS]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 16,
		},
		[GeneralSettings.CallerId]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 17,
		},
	},
	[UserRoles.PATIENT]: {
		[PatientMenu.HOME]: {
			isGeneralSetting: false,
			reDirectUrl: '/patient-home',
			value: showItem,
			orderNo: 1,
		},
		[PatientMenu.RECENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/recent',
			value: showItem,
			orderNo: 2,
		},
		[PatientMenu.APPOINTMENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/appointments',
			value: showItem,
			orderNo: 3,
		},
		[PatientMenu.HEALTH]: {
			isGeneralSetting: false,
			reDirectUrl: '/health-data',
			value: showItem,
			orderNo: 4,
		},
		[PatientMenu.SUMMARY]: {
			isGeneralSetting: false,
			reDirectUrl: '/summary',
			value: showItem,
			orderNo: 5,
		},
		[PatientMenu.HELLO]: {
			isGeneralSetting: false,
			reDirectUrl: '/my-hello-devices',
			value: showItem,
			orderNo: 6,
		},
		[GeneralSettings.ConversationHistory]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 7,
		},
		[GeneralSettings.Notifications]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 8,
		},
	},
	[UserRoles.NURSE]: {
		[NurseMenu.ROUNDING]: {
			isGeneralSetting: false,
			reDirectUrl: '/health-system',
			value: showItem,
			orderNo: 2,
		},
		[NurseMenu.WAITING_ROOM]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room',
			value: showItem,
			orderNo: 3,
		},
		[NurseMenu.CHAT]: {
			isGeneralSetting: false,
			reDirectUrl: '/nurses',
			value: showItem,
			orderNo: 4,
		},
		[NurseMenu.TEAMS]: {
			isGeneralSetting: false,
			reDirectUrl: '/teams',
			value: showItem,
			orderNo: 5,
		},
		[NurseMenu.MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/monitoring',
			value: showItem,
			orderNo: 6,
		},
		[NurseMenu.RPM]: {
			isGeneralSetting: false,
			reDirectUrl: '/patient-monitoring',
			value: showItem,
			orderNo: 7,
		},
		[NurseMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/nurse-patients',
			value: true,
			orderNo: 18,
			parentDependency: NurseMenu.RPM,
		},
		[NurseMenu.WAITING_FOR_RESULTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-results',
			value: showItem,
			orderNo: 8,
		},
		[NurseMenu.VISITORS]: {
			isGeneralSetting: false,
			reDirectUrl: '/visitors',
			value: showItem,
			orderNo: 9,
		},
		[NurseMenu.CHECK_IN]: {
			isGeneralSetting: false,
			reDirectUrl: '/nurse-home',
			value: true,
			orderNo: 15,
			isDefault: true,
		},
		[NurseMenu.LOGISTICS]: {
			isGeneralSetting: false,
			reDirectUrl: '/logistics',
			value: showItem,
			orderNo: 16,
		},
		[NurseMenu.AMBIENT_MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/ambient-monitoring',
			value: showItem,
			orderNo: 19,
		},
		[NurseMenu.CARE_EVENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/care-events-dashboard',
			value: showItem,
			orderNo: 17,
		},
		[GeneralSettings.ConversationHistory]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 10,
		},
		[GeneralSettings.Notifications]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 11,
		},
		[GeneralSettings.PoolingFlow]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 12,
		},
		[GeneralSettings.MonitoringPrecautionsLegend]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 13,
		},
		[GeneralSettings.MonitoringSessions]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 14,
		},
		[GeneralSettings.CONVERSATION_FILES]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 15,
		},
		[GeneralSettings.CONVERSATION_PHOTOS]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 16,
		},
		[GeneralSettings.CallerId]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 17,
		},
	},
	[UserRoles.DIGITAL_CLINICIAN]: {
		[DigitalClinicianMenu.CHECK_IN]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-dc',
			value: true,
			orderNo: 1,
			isDefault: true,
		},
		[DigitalClinicianMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/digital-clinician/patients',
			value: showItem,
			orderNo: 2,
		},
		[DigitalClinicianMenu.RPM]: {
			isGeneralSetting: false,
			reDirectUrl: '/vital-signs-monitoring',
			value: showItem,
			orderNo: 3,
		},
		[DigitalClinicianMenu.VISITS]: {
			isGeneralSetting: false,
			reDirectUrl: '/front-desk-visits',
			value: showItem,
			orderNo: 4,
		},
		[DigitalClinicianMenu.REGISTER_PATIENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-patient',
			value: showItem,
			orderNo: 5,
		},
	},
	[UserRoles.VISITOR]: {
		[VisitorMenu.DEVICES]: {
			isGeneralSetting: false,
			reDirectUrl: '/devices',
			value: true,
			orderNo: 1,
		},
		[GeneralSettings.CONVERSATION_FILES]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 15,
		},
		[GeneralSettings.CONVERSATION_PHOTOS]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 16,
		},
		// [FamilyMemberMenu.CHAT]: { isGeneralSetting: false, reDirectUrl: '', value: showItem, orderNo: 2 },
	},
	[UserRoles.VIRTUAL_SITTER]: {
		[VirtualSitterMenu.MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/monitoring',
			value: showItem,
			orderNo: 1,
		},
		[VirtualSitterMenu.AMBIENT_MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/ambient-monitoring',
			value: showItem,
			orderNo: 2,
		},
		[VirtualSitterMenu.TEAMS]: {
			isGeneralSetting: false,
			reDirectUrl: '/teams',
			value: showItem,
			orderNo: 3,
		},
		[GeneralSettings.MonitoringPrecautionsLegend]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 13,
		},
		[GeneralSettings.MonitoringSessions]: {
			isGeneralSetting: true,
			reDirectUrl: '',
			value: showItem,
			orderNo: 14,
		},
	},
	[UserRoles.GUEST]: {},
});

export const mobileSettings = {
	Ear: 68,
	Throat: 69,
	Heart: 70,
	Lungs: 71,
	HeartRate: 72,
	OxygenSaturation: 73,
	Temperature: 74,
	Skin: 75,
	BloodPressure: 76,
	ECG: 77,
	BloodGlucose: 78,
	Weight: 79,
	Spirometer: 80,
	Abdomen: 81,
};

export const mapCompanyRouteSettings = settings =>
	settings.filter(item => Object.values(RouteSetting).includes(item.settingTypeId));

export const buildConfigSettings = (preferences, configurableMenu) => {
	const newConfig = {};
	if (!configurableMenu) {
		return null;
	}
	Object.keys(configurableMenu).forEach(key => {
		const foundPreference = preferences.find(preference => preference.preferenceTypeId === +key);
		if (foundPreference) {
			newConfig[key] = {
				...configurableMenu[key],
				value: foundPreference.value,
			};
		} else {
			newConfig[key] = { ...configurableMenu[key], value: true };
		}
	});
	return newConfig;
};

export const buildRoleSettings = async (newSettings, defaultConfigurations = {}, role) => {
	const buildSettings = {};
	Object.keys(defaultConfigurations).forEach(key => {
		const roundingConfig = newSettings.some(config => config.settingTypeId === +key && config.roleId === role);
		buildSettings[key] = roundingConfig;
	});
	return buildSettings;
};

export const buildHealthSystemMenuConfigs = async settings => {
	const result = _.cloneDeep(getAdminConfigurableMenu(false));
	CompanyRoleObjects.forEach(item => {
		const filtredSettings = settings.filter(setting => setting.roleId === item.id);
		filtredSettings.forEach(setting => {
			if (result[item.role][setting.settingTypeId]) {
				result[item.role][setting.settingTypeId] = {
					...result[item.role][setting.settingTypeId],
					value: true,
					...(setting.variant && {
						variant: setting.variant,
					}),
				};
			}
		});
	});
	return result;
};

export const buildMobileCompanyConfigurations = (settings = []) => {
	const existsInConfigArr = key => Object.values(mobileSettings).includes(key);
	const result = {};
	settings.forEach(setting => {
		if (existsInConfigArr(setting.settingTypeId)) {
			result[setting.settingTypeId] = JSON.parse(setting.value);
		}
	});
	return result;
};

export const buildAllCompanyConfigurations = (response = []) => {
	const result = {};
	response.forEach(item => {
		if (!item.roomTypeId) {
			result[item.settingTypeId] = {
				value: ['true', 'false'].includes(item.value) ? JSON.parse(item.value) : item.value,
				isLocked: item.isLocked,
				...(item.variant && { variant: item.variant }),
			};
		}
	});

	return result;
};

export const buildRoomModesConfigurations = (response = []) => {
	const result = {};
	const enabledByDefault = [RoomModesSettings.HELLOCARE];

	response.forEach(item => {
		if (item.roomTypeId) {
			result[`${item.settingTypeId}${item.roomTypeId}`] = {
				value: JSON.parse(item.value),
				isLocked: item.isLocked,
				roomTypeId: item.roomTypeId,
			};
		}
	});

	configurableRoomType().forEach(type => {
		enabledByDefault.forEach(mode => {
			result[`${mode}${type.value}`] = {
				value: true,
				isLocked: false,
				roomTypeId: type.value,
			};
		});
	});

	return result;
};

export const defaultCustomAudioRoutingList = intl => [
	{
		enum: AudioRoutingDeviceTypes.HELLO_1,
		name: 'HELLO 1',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_2,
		name: 'HELLO 2',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_2_PLUS,
		name: 'HELLO 2+',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2-plus.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_3,
		name: 'HELLO 3',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.PHONE,
		name: intl.formatMessage({ id: 'phone' }),
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_PHONE,
			monitoring: AudioRoutingOption.ALWAYS_FROM_PHONE,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_PHONE,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromPhone' }),
				value: AudioRoutingOption.ALWAYS_FROM_PHONE,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routeExternalDetected' }),
				value: AudioRoutingOption.EXTERNAL_SPEAKER_DETECTED,
			},
		],
		image: 'iphone.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.TABLET,
		name: intl.formatMessage({ id: 'tablet' }),
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_TABLET,
			monitoring: AudioRoutingOption.ALWAYS_FROM_TABLET,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_TABLET,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromTablet' }),
				value: AudioRoutingOption.ALWAYS_FROM_TABLET,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routeExternalDetected' }),
				value: AudioRoutingOption.EXTERNAL_SPEAKER_DETECTED,
			},
		],
		image: 'tablet.svg',
	},
];

export const AdminsLogTypes = {
	HEALTH_SYSTEM_SETTINGS: {
		value: 122,
		label: 'health.system.settings.updated',
	},
	HOSPITAL_SETTINGS: { value: 100, label: 'hospital.settings.updated' },
	DEPARTMENT_SETTINGS: { value: 102, label: 'department.settings.updated' },
	FLOOR_SETTINGS: { value: 103, label: 'floor.settings.updated' },
	ROOM_SETTINGS: { value: 99, label: 'room.settings.updated' },
};

export const menuUrls = [
	'/companies',
	'/users',
	'/audit-logs',
	'/health-system',
	'/dashboard',
	'/call-logs',
	'/configurations',
	'/doctor-home',
	'/nurse-home',
	'/patient-home',
	'/waiting-room',
	'/waiting-room-inperson',
	'/patients',
	'/appointments',
	'/vital-signs-monitoring',
	'/recent',
	'/health-data',
	'/summary',
	'/my-hello-devices',
	'/nurses',
	'/teams',
	'/monitoring',
	'/patient-monitoring',
	'/waiting-results',
	'/visitors',
	'/check-in-dc',
	'/digital-clinician/patients',
	'/front-desk-visits',
	'/queue-management',
	'/sessions-queue',
	'/nurse-patients',
	'/ambient-monitoring',
	'/logistics',
	'/care-events-dashboard',
	'/fleet-management',
];

export const LandingPrivateRoutes = {
	NURSE_HOME: '/nurse-home',
	DOCTOR_HOME: '/doctor-home',
	CHECK_IN_DC: '/check-in-dc',
};

export const AiSettingsTabs = [
	{ id: 0, title: translate('monitoring') },
	{ id: 1, title: translate('aiSettings') },
];

export const MonitoringSettingTab = {
	GENERAL: 0,
	AI: 1,
};

export const MonitoringSettingsTabs = [
	{ id: MonitoringSettingTab.GENERAL, title: 'general' },
	{
		id: MonitoringSettingTab.AI,
		title: 'aiSettings',
	},
];
