class ConferenceInitData {
	/**
	 * @typedef ConferenceData
	 * @property {import('@solaborate/calls').types.CallType} callType
	 * @property {number} actioneeObjectId
	 * @property {number} actioneeObjectType import('@solaborate/calls').types.ObjectType
	 * @property {string} conferenceName
	 * @property {string} [conversationId]
	 * @property {string} [conferenceId]
	 * @property {string} [participantId]
	 * @property {import('@solaborate/calls/webrtc').TrackType[]} [localTrackTypes]
	 * @property {boolean} [isGuest]
	 * @property {boolean} [isMeetingRoom]
	 * @property {string} [interpreterId]
	 * @property {string} [refToken]
	 * @property {boolean} [isJoin]
	 * @property {number} [integrationId]
	 * @property {object} [metaData]
	 * @param {ConferenceData} param0
	 */
	constructor({
		callType,
		actioneeObjectId,
		actioneeObjectType,
		conferenceName,
		conversationId,
		conferenceId,
		participantId,
		localTrackTypes,
		isGuest,
		isMeetingRoom,
		interpreterId,
		refToken,
		isJoin,
		integrationId,
		metaData,
	}) {
		this.callType = callType;
		this.actioneeObjectId = actioneeObjectId;
		this.actioneeObjectType = actioneeObjectType;
		this.conferenceName = conferenceName;
		this.localTrackTypes = localTrackTypes;
		this.isGuest = isGuest ?? false;
		this.isJoin = isJoin ?? false;
		if (conferenceId) {
			this.conferenceId = conferenceId;
		}
		if (participantId) {
			this.participantId = participantId;
		}
		if (conversationId) {
			this.conversationId = conversationId;
		}
		if (isMeetingRoom) {
			this.isMeetingRoom = isMeetingRoom;
		}
		if (interpreterId) {
			this.interpreterId = interpreterId;
		}
		if (refToken) {
			this.refToken = refToken;
		}
		if (integrationId) {
			this.integrationId = integrationId;
		}
		if (metaData) {
			this.metaData = metaData;
		}
	}
}

export default ConferenceInitData;
