import styled, { css } from 'helpers/styled-components-default.js';

/**
 * @type {styled<"div", any, { $darkMode: boolean }, never>}
 */
const StyledCallFeedback = styled.div`
	margin: auto;
	width: 400px;
	border: 1px solid var(--gray-2);
	padding: 20px;
	background-color: var(--gray-0);
	text-align: left;

	.feedback-title {
		width: 100%;
		h4 {
			width: 90%;
			color: var(--gray-5);
		}
	}

	.stars {
		font-size: 14px;
		div {
			cursor: pointer;
			width: 80px;
			text-align: center;
		}
		svg path:hover {
			fill: var(--blue-2);
			fill-opacity: 1;
		}
		p {
			color: var(--gray-5);
		}
	}
	.call-feedback-btn {
		padding: 14px;
		width: 100%;
		margin-top: 20px;
		background: #247ef9;
		background: var(--blue-2);
		border: 0;
		border-radius: 5px;
		border-radius: var(--spacing-s);
		color: #fff;
		cursor: pointer;
		text-transform: capitalize;
	}

	.call-feedback-textarea {
		width: 100%;
		border-radius: 15px;
		background-color: #f6f6f6;
		min-height: 70px;
		margin-top: 20px;
		border: 1px solid #dcdde1;
		padding: 10px;
	}

	${props =>
		props.$darkMode &&
		css`
			background: var(--dark-theme-gray-4-v2);
			border-color: var(--dark-theme-gray-4-v2);
			color: var(--dark-theme-gray-1);
			
			.feedback-title,
			.stars {
				h4,
				p,
				i {
					color: var(--dark-theme-gray-1);
				}
			}

			.call-feedback-textarea {
				background: var(--dark-theme-gray-3);
				color: var(--dark-theme-gray-1);
			}
		`}
`;

export default StyledCallFeedback;
