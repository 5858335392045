import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Cam } from '@solaborate/calls/webrtc';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Modal, Button, ParticipantVideo } from 'calls/components/index.js';
import { useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import translate from 'i18n-translations/translate.jsx';
import { changeLocalParticipantBackground, imageUrlToBase64 } from 'calls/helpers/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import Loader from 'components/Loader.jsx';
import useVirtualBackground from 'calls/hooks/useVirtualBackground.js';
import { loadTfLite, StreamBackgroundEffect } from 'calls/views/TrackWithBackground.jsx';
import { VirtualBackgroundTypes } from 'calls/views/VirtualBackgroundProvider.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { getBlobSasPicture } from 'api/doctors.js';
import { getBackgroundImages } from 'api/configurations.js';
import { useDispatch, useSelector } from 'react-redux';
import { CompanySettings, getConfig, UserSettings } from 'constants/configurationEnums.js';
import { setUserHealthSystemPreferences } from 'api/users.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { ButtonType, UserRoles } from 'constants/enums.js';
import { SpinLoader } from 'calls/icons/index.js';
import Alert from 'components/Alert.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isLoading: boolean }, never>}
 */
const StyledVirtualBackground = styled.div`
	section {
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero)};
		header {
			border-bottom: 1px solid ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayOne)};
			position: relative;
			z-index: 1;

			h1 {
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
			}
			button {
				span {
					color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				}
			}
		}
	}

	video {
		margin-bottom: 10px;
	}

	footer {
		display: grid;
		grid-template-columns: ${props => (props.$isLoading ? 'repeat(1, minmax(0, 1fr)) ' : 'repeat(4, minmax(0, 1fr))')};
		grid-gap: 10px;

		button {
			background: transparent;
			padding: 0;
			border-radius: ${LightTheme.borderRadius.buttons}px;
			position: relative;
			span {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTwo)};
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				p {
					color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
					margin: 0;
					padding: 0;
					text-transform: none;
					font-size: 12px;
				}

				input[type='file'] {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					cursor: pointer;
				}
			}
		}
	}
`;

/**
 * @param {object} props
 * @param {() => void} props.onDismiss
 */
const VirtualBackgroundView = ({ onDismiss }) => {
	const localParticipant = useLocalParticipant();

	const { activeBackground, setActiveBackground } = useVirtualBackground();

	const [streamEffect, setStreamEffect] = useState(null);
	const [selectedBackground, setSelectedBackground] = useState({ url: VirtualBackgroundTypes.NONE, pictureUrl: null });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [backgrounds, setBackgrounds] = useState([
		{
			id: 5,
			description: translate('filterNone'),
			onClick: stream => removeBackground(stream),
			url: VirtualBackgroundTypes.NONE,
		},
		{
			id: 6,
			description: translate('filterBlur'),
			onClick: stream => blurBackground(stream),
			url: VirtualBackgroundTypes.BLUR,
		},
	]);

	const conferenceConfigs = useConferenceConfigurations();
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(true);
	const userSession = useSelector(state => state.user.userSession);
	const dispatch = useDispatch();

	// @ts-ignore
	const localTrackFactory = localParticipant.localTrackController.factory;
	const localParticipantVideoRef = useRef(null);
	const intl = useIntl();
	let modelOptions = {
		height: 144,
		width: 256,
		virtualBackground: {
			backgroundEffectEnabled: false,
			backgroundType: 'none',
		},
	};

	const getTracks = async () => {
		setIsLoading(true);
		const videoTrack = localParticipant.localTrackController.tracks[Cam];
		if (videoTrack && videoTrack instanceof StreamBackgroundEffect) {
			modelOptions = { ...videoTrack.options };
		}
		const newTracks = await localTrackFactory.createTracks([Cam]);
		const tflite = await loadTfLite();
		const sbe = new StreamBackgroundEffect(newTracks[0], tflite, modelOptions);
		setStreamEffect(sbe);
		localParticipantVideoRef.current.srcObject = new MediaStream([sbe.track]);
		setIsLoading(false);
	};

	const onApply = async () => {
		setIsLoading(false);
		if (UserRoles.GUEST === getUserRole()) {
			const params = { localParticipant, streamEffect, selectedBackground: selectedBackground.url };
			changeLocalParticipantBackground(params);
			return;
		}
		const backgroundValue = selectedBackground.pictureUrl ? selectedBackground.pictureUrl : selectedBackground.url;
		const dataToSend = {
			teamSettings: [
				{
					settingTypeId: UserSettings.CALL_BACKGROUND,
					value: backgroundValue,
				},
			],
		};

		const response = await setUserHealthSystemPreferences(userSession.healthSystem.id, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			configurationActionCreators.setUserSettings({
				[UserSettings.CALL_BACKGROUND]: backgroundValue,
			})
		);
		setActiveBackground(selectedBackground.url);
		const params = { localParticipant, streamEffect, selectedBackground: selectedBackground.url };
		changeLocalParticipantBackground(params);
		modelOptions = { ...streamEffect.options };
	};

	const onCancel = () => {
		if (streamEffect) {
			streamEffect.stop();
		}
		onDismiss();
	};

	const onSelectBackground = async (url, pictureUrl) => {
		const base64Image = await imageUrlToBase64(url);
		setSelectedBackground({ url, pictureUrl });
		if (streamEffect) {
			streamEffect.changeBackground(base64Image);
		}
		getStorage().setItem('virtualBackground', base64Image);
	};

	const removeBackground = (stream = streamEffect) => {
		setSelectedBackground({ url: VirtualBackgroundTypes.NONE, pictureUrl: null });
		if (stream) {
			stream.removeBackground();
		}
		getStorage().setItem('virtualBackground', 'none');
	};

	const blurBackground = (stream = streamEffect) => {
		setSelectedBackground({ url: VirtualBackgroundTypes.BLUR, pictureUrl: null });
		if (stream) {
			stream.blurBackground(20);
		}
		getStorage().setItem('virtualBackground', 'blur');
	};

	useEffect(() => {
		if (activeBackground) {
			setSelectedBackground({ url: activeBackground, pictureUrl: null });
		}

		const mapBlob = async (pictureUrl, index) => {
			const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
			if (response.error) {
				setError(response.error.message);
				return null;
			}
			const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
			return imgToUpload;
		};

		const fetchImages = async () => {
			if (UserRoles.GUEST === getUserRole()) {
				setIsBackgroundImgsLoading(false);
				setIsLoading(false);
				if (localStorage['virtualBackground'] === 'blur') {
					blurBackground();
				} else {
					removeBackground();
				}
				return;
			}
			const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
			const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
			const defaultHsBackground = visualSettings[CompanySettings.HS_DEFAULT_BACKGROUND]?.value;

			if (callBackground === VirtualBackgroundTypes.BLUR) {
				blurBackground();
				setIsBackgroundImgsLoading(false);
			}
			if (callBackground === VirtualBackgroundTypes.NONE) {
				removeBackground();
				setIsBackgroundImgsLoading(false);
			}

			if (!backgroundConfig.value) {
				setIsBackgroundImgsLoading(false);
				return;
			}

			const response = await getBackgroundImages(getCompanyId(), userSession.healthSystem.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));
			if (images) {
				setBackgrounds(prevState => [...prevState, ...images]);
			}
			setIsBackgroundImgsLoading(false);

			if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(callBackground) && callBackground) {
				getBackground(callBackground, images);
				return;
			}

			if (defaultHsBackground === VirtualBackgroundTypes.BLUR) {
				blurBackground();
				setIsBackgroundImgsLoading(false);
				return;
			}

			if (defaultHsBackground === VirtualBackgroundTypes.NONE) {
				removeBackground();
				setIsBackgroundImgsLoading(false);
				return;
			}

			if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(defaultHsBackground)) {
				getBackground(defaultHsBackground, images);
			}
		};
		// this needs to be run only when component mounts
		// eslint-disable-next-line react-hooks/exhaustive-deps
		getTracks();
		fetchImages();
	}, []);

	const getBackground = async (background, images) => {
		let blobResponse = {};
		if (background) {
			blobResponse = await getBlobSasPicture(background, 'team-call-background-pictures');
		}
		if (!blobResponse.error && !blobResponse.errorResponse) {
			const found = images.find(item => item.pictureUrl === background);
			if (found) {
				onSelectBackground(blobResponse.result.uri.uri, background);
			}
		}
	};

	return (
		<StyledVirtualBackground $isDarkMode={conferenceConfigs.isDarkMode} $isLoading={isBackgroundImgsLoading}>
			<Modal onDismiss={onCancel} title={intl.formatMessage({ id: 'selectBackground' })}>
				<Modal.Content>
					<main>
						{isLoading && (
							<div className='virtual-background-loader'>
								<Loader />
							</div>
						)}
						<ParticipantVideo
							className={classNames(
								isMobile ? 'virtual-background-video-mobile' : '',
								isLoading ? 'virtual-background-video-loading' : 'virtual-background-video'
							)}
							ref={localParticipantVideoRef}
							track={localParticipant.localTrackController.tracks[Cam]}
							autoPlay={true}
						/>
						<footer>
							{!isBackgroundImgsLoading &&
								backgrounds.map(({ url, id, pictureUrl, description, onClick }) => (
									<>
										{onClick && (
											<button
												style={{ height: '100px' }}
												key={id}
												type='button'
												onClick={() => onClick(streamEffect)}
												className={
													selectedBackground.url === url ? 'virtual-background-image-selected' : 'virtual-background-image-button'
												}>
												<span className='virtual-background-text'>
													{url === VirtualBackgroundTypes.BLUR && <i className='material-icons'>blur_on</i>}
													{url === VirtualBackgroundTypes.NONE && <i className='material-icons'>no_accounts</i>}
													<p>{description}</p>
												</span>
											</button>
										)}
										{!onClick && (
											<button
												key={id}
												type='button'
												onClick={() => onSelectBackground(url, pictureUrl)}
												className={
													selectedBackground.url === url ? 'virtual-background-image-selected' : 'virtual-background-image-button'
												}>
												<img src={url} alt='Virtual background thumbnail' style={{ height: '100px' }} />
											</button>
										)}
									</>
								))}
							{isBackgroundImgsLoading && (
								<div className='center-circle-loader'>
									<SpinLoader />
								</div>
							)}
						</footer>
						<div className='virtual-background-buttons-container'>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								disabled={!streamEffect}
								className={classNames(
									'virtual-background-select-bg-button',
									conferenceConfigs.isDarkMode ? 'virtual-background-dark-select-bg-button' : ''
								)}
								onClick={() => {
									onApply();
									onDismiss();
								}}>
								{translate('selectBackground')}
							</Button>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								className={classNames(
									'virtual-background-cancel-button',
									conferenceConfigs.isDarkMode ? 'virtual-background-dark-cancel-button' : ''
								)}
								onClick={onCancel}>
								{translate('cancel')}
							</Button>
						</div>
					</main>
				</Modal.Content>
			</Modal>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</StyledVirtualBackground>
	);
};

export default VirtualBackgroundView;
